/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-01-09 10:15:22
 * @LastEditTime: 2024-08-12 17:17:10
 */
// limit 数组中0代表医生 1代表技工账号
let limitMap = [
  { id: '1', name: "下载文件", limit: [0, 1] },//limit数组里有0和1 代表0 1都可以开放下载文件功能
  { id: '2', name: "添加合作伙伴", limit: [0] },//limit数组里有0 代表类型0账号 可以开放添加合作伙伴功能
  { id: '3', name: "删除合作伙伴", limit: [0] },//limit数组里有0 代表类型0账号 可以开放删除合作伙伴
  { id: '4', name: "取消文件", limit: [0] },
  { id: '5', name: "上传文件", limit: [0] },
  { id: '6', name: "重新发送邀请", limit: [0] },
]
import { PublicStore } from "@/tools/pinia/store";
export default {
  install(Vue) {
    Vue.directive('limit', {
      mounted(el, binding) {
        let PublicStores = PublicStore()
        let UserType = PublicStores.UserInfo.UserType
        let mapitem = limitMap.find((item) => item.id == binding.value)
        let isShow = mapitem.limit.includes(UserType)
        if (!isShow) {
          el.remove();
        }
      },
    });
  },
};

